import Entities from 'STORE';
import { GlobalVideoPlayerType } from 'STORE/GlobalVideoPlayer/GlobalVideoPlayer.types';
import { IVideoPlayerConfig } from 'UIKIT/Media/VideoPlayer/VideoPlayer.types';

import { setPlayerConfig } from '../playerConfig/playerConfig';

interface IInitVideoPlayer {
  container: HTMLElement | null;
  url: string;
  previewUrl: string | null;
  type?: GlobalVideoPlayerType;
  isPlaying?: boolean;
  config?: Partial<IVideoPlayerConfig>;
  onExpandMini?: (() => void) | null;
  onShowMini?: (() => void) | null;
}

export function initVideoPlayer({
  container,
  url,
  type = GlobalVideoPlayerType.Default,
  isPlaying = true,
  previewUrl = null,
  config = {},
  onExpandMini = null,
  onShowMini = null,
}: IInitVideoPlayer): void {
  const globalVideoPlayer = Entities.GlobalVideoPlayer;

  globalVideoPlayer.setContainer(container);
  globalVideoPlayer.setType(type);
  globalVideoPlayer.setUrl(url);
  globalVideoPlayer.setPreviewUrl(previewUrl);
  globalVideoPlayer.setPlaying(isPlaying);
  globalVideoPlayer.setExpandMiniHandler(onExpandMini);
  globalVideoPlayer.setShowMiniHandler(onShowMini);
  setPlayerConfig(config);
}
