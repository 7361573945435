import { reaction } from 'mobx';
import { useLocalStore } from 'mobx-react';
import { MouseEvent, useEffect } from 'react';
import { DraggableEvent, DraggableData } from 'react-draggable';

import Tasks from 'APP/Tasks';
import { AudioSourceType } from 'APP/constants/app';
import Entities from 'STORE';
import {
  GlobalVideoPlayerType,
  IGlobalVideoPlayerConfig,
} from 'STORE/GlobalVideoPlayer/GlobalVideoPlayer.types';
import { VideoPlayerType } from 'UIKIT/Media/VideoPlayer/VideoPlayer.constants';

type TPosition = { x: number; y: number };

const DRAGGABLE_MIN_DIFF = 5;

const defaultGlobalVideoPlayerConfig = {
  speed: 1,
};

interface IGlobalVideoPlayerPresenter {
  container: HTMLDivElement;
  positionCoordinates: TPosition;
  isPlaying: boolean;
  position: TPosition;
  isFullScreenMode: boolean;
  removeAudioSource: (() => void) | null;
  isDragged: boolean;
  url: string | null;
  previewUrl: string | null;
  playerType: VideoPlayerType | null;
  canShowMiniPlayer: boolean;
  canExpandMiniPlayer: boolean;
  isHidden: boolean;
  isDraggable: boolean;
  isMini: boolean;
  isRound: boolean;
  progress: number | null;
  playerConfig: Partial<IGlobalVideoPlayerConfig>;
  onShowMiniPlayer(): void;
  onExpandMiniPlayer(): void;
  setPlayerConfig(config: Partial<IGlobalVideoPlayerConfig>): void;
  onDragEnd(_: DraggableEvent, data: DraggableData): void;
  pauseVideo(): void;
  onTogglePlay(isPlaying: boolean): void;
  onToggleFullScreenMode(isFullScreenMode: boolean): void;
  onProgressUpdate(progress: number): void;
  onChildrenClick(event: MouseEvent): void;
  onLoadedFirstFrame(): void;
  onLoadedError(): void;
  onClose(): void;
}

export function useGlobalVideoPlayerPresenter(): IGlobalVideoPlayerPresenter {
  const presenter = useLocalStore<IGlobalVideoPlayerPresenter>(() => ({
    container: document.createElement('div'),
    positionCoordinates: { x: 0, y: 0 },
    isFullScreenMode: false,
    removeAudioSource: null,
    isDragged: false,

    get url(): string | null {
      return Entities.GlobalVideoPlayer.url;
    },

    get previewUrl(): string | null {
      return Entities.GlobalVideoPlayer.previewUrl;
    },

    get playerType(): VideoPlayerType | null {
      switch (Entities.GlobalVideoPlayer.type) {
        case GlobalVideoPlayerType.Default:
          return VideoPlayerType.Default;
        case GlobalVideoPlayerType.Mini:
          return VideoPlayerType.Mini;
        default:
          return null;
      }
    },

    get isPlaying(): boolean {
      return Entities.GlobalVideoPlayer.isPlaying;
    },

    get position(): TPosition {
      if (!presenter.isDraggable) {
        return { x: 0, y: 0 };
      }

      return presenter.positionCoordinates;
    },

    onShowMiniPlayer(): void {
      const globalVideoPlayer = Entities.GlobalVideoPlayer;

      globalVideoPlayer.setContainer(null);
      globalVideoPlayer.setType(GlobalVideoPlayerType.Mini);
      if (globalVideoPlayer.onShowMiniHandler) {
        globalVideoPlayer.onShowMiniHandler();
      }
    },

    get canShowMiniPlayer(): boolean {
      return !!Entities.GlobalVideoPlayer.onShowMiniHandler;
    },

    onExpandMiniPlayer(): void {
      const globalVideoPlayer = Entities.GlobalVideoPlayer;

      if (globalVideoPlayer.onExpandMiniHandler) {
        globalVideoPlayer.onExpandMiniHandler();
      }
    },

    get canExpandMiniPlayer(): boolean {
      return !!Entities.GlobalVideoPlayer.onExpandMiniHandler;
    },

    get isHidden(): boolean {
      return !Entities.GlobalVideoPlayer.isOpened;
    },

    get isDraggable(): boolean {
      return presenter.isMini;
    },

    get isMini(): boolean {
      if (!Entities.GlobalVideoPlayer.type) {
        return false;
      }

      return [GlobalVideoPlayerType.Mini, GlobalVideoPlayerType.MiniRound].includes(
        Entities.GlobalVideoPlayer.type
      );
    },

    get isRound(): boolean {
      if (!Entities.GlobalVideoPlayer.type) {
        return false;
      }

      return [GlobalVideoPlayerType.Round, GlobalVideoPlayerType.MiniRound].includes(
        Entities.GlobalVideoPlayer.type
      );
    },

    get progress(): number | null {
      return Entities.GlobalVideoPlayer.newProgress;
    },

    get playerConfig(): Partial<IGlobalVideoPlayerConfig> {
      return Entities.GlobalVideoPlayer.config;
    },

    setPlayerConfig(config: Partial<IGlobalVideoPlayerConfig>): void {
      Tasks.globalVideoPlayer.setPlayerConfig(config);
    },

    onDragEnd(_: DraggableEvent, data: DraggableData): void {
      presenter.isDragged =
        Math.abs(presenter.positionCoordinates.x - data.x) > DRAGGABLE_MIN_DIFF ||
        Math.abs(presenter.positionCoordinates.y - data.y) > DRAGGABLE_MIN_DIFF;

      presenter.positionCoordinates = {
        x: data.x,
        y: data.y,
      };
    },

    pauseVideo(): void {
      Entities.GlobalVideoPlayer.setPlaying(false);
      presenter.isFullScreenMode = false;
    },

    onTogglePlay(isPlaying: boolean): void {
      Entities.GlobalVideoPlayer.setPlaying(isPlaying);

      if (isPlaying && !presenter.removeAudioSource) {
        presenter.removeAudioSource = Tasks.app.audioSource.setCurrentSource(
          AudioSourceType.Video,
          presenter.pauseVideo
        );
      }

      if (!isPlaying && presenter.removeAudioSource) {
        presenter.removeAudioSource();
        presenter.removeAudioSource = null;
      }
    },

    onToggleFullScreenMode(isFullScreenMode: boolean): void {
      presenter.isFullScreenMode = isFullScreenMode;
    },

    onProgressUpdate(progress: number): void {
      Entities.GlobalVideoPlayer.setProgress(progress);
    },

    onChildrenClick(event: MouseEvent): void {
      if (presenter.isDragged) {
        event.preventDefault();
        event.stopPropagation();
        presenter.isDragged = false;
      }
    },

    onLoadedFirstFrame(): void {
      Entities.GlobalVideoPlayer.setFirstFrameLoaded(true);
    },

    onLoadedError(): void {
      presenter.onTogglePlay(false);
    },

    onClose(): void {
      Tasks.globalVideoPlayer.closeVideoPlayer();
      Tasks.globalVideoPlayer.setPlayerConfig({ ...defaultGlobalVideoPlayerConfig });
    },
  }));

  useEffect(() => {
    document.body.appendChild(presenter.container);

    const dispose = reaction(
      () => Entities.GlobalVideoPlayer.container,
      (target) => {
        if (target) {
          target.appendChild(presenter.container);
        } else {
          document.body.appendChild(presenter.container);
        }
      }
    );

    return () => {
      document.body.removeChild(presenter.container);
      dispose();
    };
  }, []);

  useEffect(() => {
    Tasks.globalVideoPlayer.initPlayerConfig();
  }, []);

  useEffect(() => {
    if (!Entities.GlobalVideoPlayer.isOpened) {
      presenter.onClose();
    }
  }, [Entities.GlobalVideoPlayer.isOpened]);

  return presenter;
}
